import React from 'react'
import { Link } from 'react-router-dom'

const NewSide = () => {
  return (
    <div >
 <aside id="sidebar" className="sidebar" style={{background: 'linear-gradient(135deg, #003366, #3366cc)'}}>
  <ul className="sidebar-nav" id="sidebar-nav">
    <li className="nav-item">
      <a className="nav-link " href="/dashboard">
        <i className="bi bi-grid" />
        <span>Dashboard</span>
      </a>
    </li>{/* End Dashboard Nav */}
    <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
        <i className="bi bi-wallet" /><span>Transactions</span><i className="bi bi-chevron-down ms-auto" />
      </a>
      <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li>
          <a href="/send">
            <i className="bi bi-circle" /><span>Create Cash In</span>
          </a>
        </li>
        <li>
          <a href="/makeDeposit">
            <i className="bi bi-circle" /><span>Create Deposit</span>
          </a>
        </li>
        <li>
          <a href="/cashout">
            <i className="bi bi-circle" /><span>Create Cash Out</span>
          </a>
        </li>

      </ul>
    </li>{/* End Components Nav */}
    <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
        <i className="bi bi-person" /><span>User Accounts</span><i className="bi bi-chevron-down ms-auto" />
      </a>
      <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li>
          <a href="/create-user">
            <i className="bi bi-circle" /><span>Create User Accounts</span>
          </a>
        </li>
        <li>
          <a href="/create-beneficiary">
            <i className="bi bi-circle" /><span>Create Beneficiary</span>
          </a>
        </li>
      
     
      </ul>
    </li>{/* End Forms Nav */}
    <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
        <i className="bi bi-graph-up" /><span>Financial Management</span><i className="bi bi-chevron-down ms-auto" />
      </a>
      <ul id="tables-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li>
          <a href="/float">
            <i className="bi bi-circle" /><span>Request Float</span>
          </a>
        </li>
        <li>
          <a href="/convert">
            <i className="bi bi-circle" /><span>Change Currency</span>
          </a>
        </li>


        <li>
          <a href="/cintofloat">
            <i className="bi bi-circle" /><span>Convert Cashin to Float</span>
          </a>
        </li>
      </ul>
    </li>{/* End Tables Nav */}
   
 
   
    <li className="nav-item">
      <a className="nav-link collapsed" href="/reports">
        <i className="bi bi-file-earmark-text" />
        <span>Reports</span>
      </a>
    </li>{/* End Profile Page Nav */}
    <li className="nav-item">
      <a className="nav-link collapsed" href="/settings">
        <i className="bi bi-gear" />
        <span>Settings</span>
      </a>
    </li>{/* End F.A.Q Page Nav */}
  
   
    <Link to="/logout" className="nav-link" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-sign-out-alt"></i>
                </div>
                Logout
              </Link>
  
  
  
  </ul>
</aside>
</div>

  )
}

export default NewSide