import React from 'react'
import ChangeCurrComp from '../Components/ChangeCurrComp'
import SideNav from '../Components/SideNav'
import NewSide from '../Components/NewSide'

const ChangeCurrency = () => {
  return (
    <div>
    <NewSide/>
  <ChangeCurrComp/>
  </div>
  )
}

export default ChangeCurrency