import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransSendComp from '../Components/TransSendComp'
import NewSide from '../Components/NewSide'

function TransSend() {
  return (
    <div>

    <NewSide/>
    <TransSendComp/>
    </div>
  )
}

export default TransSend
