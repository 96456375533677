import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Paper, Typography } from '@mui/material';    

function RequestFloatComp() {
  const [userId, setUserId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [topup, setTopup] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        setUserId(response.data.data.id);
        console.log(userId)
      } catch (error) {
        setResultMessage({ type: 'danger', text: 'Failed to load user ID.' });
      }
    };

    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        setCurrencies(response.data.data);
      } catch (error) {
        setResultMessage({ type: 'danger', text: 'Failed to load currencies.' });
      }
    };

    fetchUserId();
    fetchCurrencies();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      user_id: userId,
      currency_id: currencyId,
      topup: topup,
    };

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Agent/float-request', requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
        }
      });

      setLoading(false);

      if (response.status === 200) {
        setResultMessage({ type: 'success', text: 'Float request submitted successfully!' });
        console.log(response)
      } else {
        setResultMessage({ type: 'danger', text: response.data.message || 'Failed to submit float request.' });
      }
    } catch (error) {
      setLoading(false);
      setResultMessage({ type: 'danger', text: 'An error occurred. Please try again.' });
      console.log(error)
    }
  };

  

  return (
    <main id="main" className="main">
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card className="p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <Card.Body>
        <div style={{ marginLeft: '40%' }}>
 <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          justifyContent: 'center',
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Request Float
        </Typography>
      </Paper>
      </div>
          {resultMessage && (
            <Alert variant={resultMessage.type}>{resultMessage.text}</Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="currencyId" className="mt-3">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                value={currencyId}
                onChange={(e) => setCurrencyId(e.target.value)}
                required
                size="sm"
              >
                <option value="">Select Currency</option>
                {currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.name} ({currency.code})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="topup" className="mt-3">
              <Form.Label>Topup Amount</Form.Label>
              <Form.Control
                type="number"
                value={topup}
                onChange={(e) => setTopup(e.target.value)}
                required
                size="sm"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-4 w-100"
              disabled={loading}
            >
              {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Request'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
    </main>
  );
}

export default RequestFloatComp;
