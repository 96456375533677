import React from 'react'
import SideNav from '../Components/SideNav'
import ConvertCTFloatComp from '../Components/ConvertCTFloatComp'
import NewSide from '../Components/NewSide'

const ConvertCTFloat = () => {
  return (
    <div>
<NewSide/>
<ConvertCTFloatComp/>
</div>
  )
}

export default ConvertCTFloat