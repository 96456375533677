import React from 'react'
import SettingsComp from '../Components/SettingsComp'
import SideNav from '../Components/SideNav'
import NewSide from '../Components/NewSide'

const Settings = () => {
  return (
    <div>
    <NewSide/>
    <SettingsComp/>
    </div>
  )
}

export default Settings