import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import CreateUserComp from '../Components/CreateUserComp'
import NewSide from '../Components/NewSide'

function CreateUser() {
  return (
    <div>
      
      <NewSide/>
      <CreateUserComp/>
    </div>
  )
}

export default CreateUser
