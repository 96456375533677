import React from 'react'
import MakeDepComp from '../Components/MakeDepComp'
import NewSide from '../Components/NewSide'

function MakeDeposit() {
  return (
    <div>
    <NewSide/>
    <MakeDepComp/>  
    </div>
  )
}

export default MakeDeposit
