import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { jsPDF } from 'jspdf';

function CashoutComp() {
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false); 
  const [cashoutDetails, setCashoutDetails] = useState({});
  const[agentName, setAgentName] = useState();
  const[surname,setSurname] = useState()

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const token = localStorage.getItem('agent_token');
        const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrencies(response.data.data);
      } catch (error) {
        console.error('Failed to fetch currencies', error);
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(()=>{

async function getAgentName(){
  try {
    const token = localStorage.getItem('agent_token');
    const agent = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
      headers:{
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Application-Type': 'application/json',
      }
    })

    setAgentName(agent.data.data.name)
    setSurname(agent.data.data.surname)
  } catch (error) {
   console.log(error) 
  }

}
getAgentName()
  },[])

  const getCurrencyName = (currencyId) => {
    const currency = currencies.find((cur) => cur.id === currencyId);
    return currency ? currency.name : 'Unknown currency';
  };

  const handleRequestCashout = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/request_cashout',
        { transaction_id: transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const transaction = response.data.transaction;
      const beneficiary = response.data.beneficiary;

      const cashoutAmount = transaction.initial_transfer_amount;
      const currencyId = transaction.currency_id;
      const beneficiaryName = beneficiary.full_name;

      const currencyName = getCurrencyName(currencyId);

      setCashoutDetails({
        amount: cashoutAmount,
        currencyName,
        beneficiaryName,
        transactionId: transactionId,
        date: new Date().toLocaleString(),
      });

      setConfirmationOpen(true);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred. Please try again.');
        console.log(error);
      } else {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };


  const generatePDFReceipt = () => {
    const doc = new jsPDF();
  
    // Add logo
    const logo = "Logoo.png"; // Path to the logo
    doc.addImage(logo, "JPEG", 20, 10, 30, 30); // Add logo (x, y, width, height)
  
    // Add title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Nkolozi Money Transfer", 60, 20);
    doc.setFontSize(12);
    doc.text("Cashout Transaction Receipt", 60, 30);
  
    // Horizontal line
    doc.setDrawColor(0, 0, 0);
    doc.line(20, 40, 190, 40);
  
    // Transaction details (with more spacing)
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Transaction ID: ${cashoutDetails.transactionId}`, 20, 50);
    doc.text(`Beneficiary: ${cashoutDetails.beneficiaryName}`, 20, 60);
    doc.text(`Agent: ${agentName} ${surname}`, 20, 70);
    doc.text(`Amount: ${cashoutDetails.currencyName} ${cashoutDetails.amount}`, 20, 80);
    doc.text(`Date: ${cashoutDetails.date}`, 20, 90);
  
    // Footer
    doc.setFontSize(10);
    doc.text("Thank you for using Nkolozi Money Transfer!", 20, 110);
  
    return doc.output("datauristring");
  };
  



  const handleConfirmCashout = async () => {
    setLoading(true);
    setConfirmationOpen(false);

    try {
      const token = localStorage.getItem('agent_token');
      const confirmResponse = await axios.post(
        'https://api.nkolozi.com/api/Agent/cashout',
        { transaction_id: transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (confirmResponse.status === 200) {
        setSuccess('Cashout confirmed successfully.');
        setPreviewOpen(true); // Open the receipt preview after confirmation
      } else {
        setError('Failed to confirm cashout. Please try again.');
      }
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleDownloadReceipt = () => {
    const doc = new jsPDF();
  
    // Add logo
    const logo = "Logoo.png";
    doc.addImage(logo, "JPEG", 20, 10, 30, 30);
  
    // Title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Nkolozi Money Transfer", 60, 20);
    doc.setFontSize(12);
    doc.text("Cashout Transaction Receipt", 60, 30);
  
    // Horizontal line
    doc.setDrawColor(0, 0, 0);
    doc.line(20, 40, 190, 40);
  
    // Transaction details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Transaction ID: ${cashoutDetails.transactionId}`, 20, 50);
    doc.text(`Beneficiary: ${cashoutDetails.beneficiaryName}`, 20, 60);
    doc.text(`Agent: ${agentName} ${surname}`, 20, 70);
    doc.text(`Amount: ${cashoutDetails.currencyName} ${cashoutDetails.amount}`, 20, 80);
    doc.text(`Date: ${cashoutDetails.date}`, 20, 90);
  
    // Footer
    doc.setFontSize(10);
    doc.text("Thank you for using Nkolozi Money Transfer!", 20, 110);
  
    // Save the document
    doc.save(`Cashout_Receipt_${cashoutDetails.transactionId}.pdf`);
    setPreviewOpen(false);
  };
  

  const handlePrintReceipt = () => {
    const doc = new jsPDF();
  
    // Add logo
    const logo = "Logoo.png";
    doc.addImage(logo, "JPEG", 20, 10, 30, 30);
  
    // Title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Nkolozi Money Transfer", 60, 20);
    doc.setFontSize(12);
    doc.text("Cashout Transaction Receipt", 60, 30);
  
    // Horizontal line
    doc.setDrawColor(0, 0, 0);
    doc.line(20, 40, 190, 40);
  
    // Transaction details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Transaction ID: ${cashoutDetails.transactionId}`, 20, 50);
    doc.text(`Beneficiary: ${cashoutDetails.beneficiaryName}`, 20, 60);
    doc.text(`Agent: ${agentName} ${surname}`, 20, 70);
    doc.text(`Amount: ${cashoutDetails.currencyName} ${cashoutDetails.amount}`, 20, 80);
    doc.text(`Date: ${cashoutDetails.date}`, 20, 90);
  
    // Footer
    doc.setFontSize(10);
    doc.text("Thank you for using Nkolozi Money Transfer!", 20, 110);
  
    // Print the document
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
    setPreviewOpen(false);
  };

  return (
    <main id="main" className="main">
    <div className="container mt-5" >
      <div className="card">
        <div className="card-header text-center">
          <div style={{ marginLeft: '40%' }}>
            <Paper
              sx={{
                padding: '3px 10px',
                marginBottom: '20px',
                width: 'fit-content',
                backgroundColor: '#c5401f',
                justifyContent: 'center',
                '@keyframes slide-up': {
                  '0%': {
                    transform: 'translateY(20px)',
                    opacity: 0,
                  },
                  '100%': {
                    transform: 'translateY(0)',
                    opacity: 1,
                  },
                },
                animation: 'slide-up 0.5s ease-in-out',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
                Cashout
              </Typography>
            </Paper>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleRequestCashout}>
            <div className="mb-3">
              <label htmlFor="transactionId" className="form-label">Transaction ID</label>
              <input
                type="text"
                id="transactionId"
                name="transactionId"
                className="form-control form-control-sm"
                placeholder="Enter Transaction ID"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                required
                style={{
                  width: '100%',
                  maxWidth: '300px',
                }}
              />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-sm" style={{ width: '200px', marginRight: '70%' }} disabled={loading}>
                {loading ? 'Processing...' : 'Request Cashout'}
              </button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          {"Confirm Cashout"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            You are about to cash out {cashoutDetails.currencyName} {cashoutDetails.amount} to {cashoutDetails.beneficiaryName}. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmCashout} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={previewOpen}
        onClose={handleClosePreview}
        aria-labelledby="preview-dialog-title"
        aria-describedby="preview-dialog-description"
      >
        <DialogTitle id="preview-dialog-title">
          {"Receipt Preview"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="preview-dialog-description">
            <iframe
              src={generatePDFReceipt()}
              width="100%"
              height="400px"
              frameBorder="0"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} color="secondary">
            Close
          </Button>
          <Button onClick={handlePrintReceipt} color="primary">
            Print
          </Button>
          <Button onClick={handleDownloadReceipt} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </main>
  );
}

export default CashoutComp;
