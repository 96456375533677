import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import RequestFloatComp from '../Components/RequestFloatComp'
import NewSide from '../Components/NewSide'

function RequestFloat() {
  return (
    <div>
   
      <NewSide/>
      <RequestFloatComp/>
    </div>
  )
}

export default RequestFloat
