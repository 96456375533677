import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Reports2 from './Reports2';


const ReportsComp = () => {
  const [details, setDetails] = useState([]);
  const [cashout, setCashout] = useState('');
  const [cashin, setCashin] = useState('');
  const [currencies, setCurrencies] = useState({}); // Store currencies in an object for quick lookup
  const [isCollapsed, setIsCollapsed] = useState(false);


   // New state for pie chart data
   const [nkoloziStackTotal, setNkoloziStackTotal] = useState(0);
   const [userStackTotal, setUserStackTotal] = useState(0);
   useEffect(() => {
     async function fetchCurrencies() {
       try {
         const currencyResponse = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('agent_token')}`
           }
         });
         
         const currencyMap = {};
         currencyResponse.data.data.forEach(currency => {
           currencyMap[currency.id] = currency.name; // Map currency ID to name
         });
         
         setCurrencies(currencyMap);
       } catch (error) {
         console.error('Error fetching currencies:', error);
       }
     }
 
     async function fetchAgentAccount() {
       try {
         const userResponse = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('agent_token')}`
           }
         });
 
         const userId = userResponse.data.data.id;
 
         const response = await axios.get(`https://api.nkolozi.com/api/Agent/agent-accounts/${userId}`, {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('agent_token')}`
           }
         });
 
         if (Array.isArray(response.data.agent_accounts)) {
           setDetails(response.data.agent_accounts);
           setCashout(response.data.agent_accounts[0].cashout_amount);
           setCashin(response.data.agent_accounts[0].cashin_user_stack);
 
 
           const agentAccounts = response.data.agent_accounts;
           // Calculate the total cashin and cashout amounts
           const totalCashin = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_user_stack), 0);
           const totalCashout = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashout_amount), 0);
 
           // Calculate totals for Pie Chart
           const totalNkoloziStack = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_nkolozi_stack), 0);
           const totalUserStack = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_user_stack), 0);
           setCashin(totalCashin);
           setCashout(totalCashout);
           setNkoloziStackTotal(totalNkoloziStack);
           setUserStackTotal(totalUserStack);
 
           
         } else {
           console.error('Unexpected data format:', response.data.agent_accounts);
         }
       } catch (error) {
         console.log(error);
       }
     }
 
     fetchCurrencies();
     fetchAgentAccount();
   }, []);
 
   const toggleSidebar = () => {
     setIsCollapsed(!isCollapsed);
   };
 



  return (
    <main id="main" className="main">
    <div >
    <div >
                  <div >
                    <div className="card border-0 shadow-lg rounded-lg">
                      <div className="card-header text-white text-center py-4" style={{ background: 'linear-gradient(135deg, #003366, #3366cc)', position: 'relative' }}>
                        <div
                          className="bg-primary text-white d-flex align-items-center justify-content-center shadow-lg"
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
                            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                            position: 'absolute',
                            top: '20px',
                            left: '10px',
                            fontSize: '0.8rem'
                          }}
                        >
                          <div className="text-center">
                            <h6 className="mb-1">Cashin</h6>
                            <h5 className="font-weight-bold">{cashin}</h5>
                          </div>
                        </div>
                        <h4 className="mb-0">Agent Float Balances</h4>
                        <div
                          className="bg-danger text-white d-flex align-items-center justify-content-center shadow-lg"
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
                            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                            position: 'absolute',
                            top: '20px',
                            right: '10px',
                            fontSize: '0.8rem'
                          }}
                        >
                          <div className="text-center">
                            <h6 className="mb-1">Cashout</h6>
                            <h5 className="font-weight-bold">${cashout}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-4">
                        {Array.isArray(details) && details.length > 0 ? (
                          <div className="row">
                            {details.map((account, index) => (
                              <div className="col-lg-3 col-md-4 mb-3" key={index}>
                                <div className="card border-light rounded shadow-sm">
                                  <div className="card-body">
                                    <h5 className="card-title mb-2">Currency: {currencies[account.currency_id] || account.currency_id}</h5>
                                    <p className="card-text">Float Balance:</p>
                                    <h3 className="font-weight-bold text-primary">${account.float_balance}</h3>
                                  </div>
                                  <div className="card-footer bg-light text-muted text-center">
                                    Updated at: {new Date(account.updated_at).toLocaleDateString()}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-center text-muted">No agent accounts found.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div> 
<Reports2/>

     
    </div>
    </main>
  )
}

export default ReportsComp