import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CashinvsCashout = () => {
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Function to fetch agent accounts data
    const fetchAgentAccount = async () => {
      try {
        const userResponse = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });

        const userId = userResponse.data.data.id;

        const accountsResponse = await axios.get(`https://api.nkolozi.com/api/Agent/agent-accounts/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });

        setAccounts(accountsResponse.data.agent_accounts);
      } catch (error) {
        console.error('Error fetching agent accounts:', error);
      }
    };

    // Function to fetch currency data
    const fetchCurrencies = async () => {
      try {
        const currenciesResponse = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });

        setCurrencies(currenciesResponse.data.data);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    // Fetch both data sets and set loading to false after
    const fetchData = async () => {
      await Promise.all([fetchAgentAccount(), fetchCurrencies()]);
      setLoading(false); // Set loading to false after both API calls are complete
    };

    fetchData();
  }, []);

  // Function to get currency code by ID
  const getCurrencyCode = (currencyId) => {
    const currency = currencies.find((curr) => curr.id === parseInt(currencyId, 10)); // Ensure IDs are compared as numbers
    return currency ? currency.code : 'Unknown';
  };

  // If loading, show a loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }}>
      <div className='card p-2' style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#0072B2',
        color: '#fff',
        width: '60%',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        lineHeight: '1.5',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '20px', // Allows scrolling if content overflows
      }}>
        {accounts.map((account, index) => (
          <div key={account.id} style={{ marginRight: '30px' }}>
           
            <strong>Cashin:</strong>  {getCurrencyCode(account.currency_id)} {account.cashin_amount_total} 
            <strong> Cashout:</strong> {getCurrencyCode(account.currency_id)}  {account.cashout_amount}
            {index !== accounts.length - 1 && ''} {/* Separator between account details */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CashinvsCashout;
