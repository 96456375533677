import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

function ClientSelector({ onClientSelect }) {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/clients', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
          },
        });
        console.log('Clients fetched:', response.data.data);
        setClients(
          response.data.data.map((client) => ({
            value: client.id,
            label: `${client.name} ${client.surname}`,
          }))
        );
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    onClientSelect(selectedOption?.value || '');
    console.log('Selected client ID:', selectedOption?.value);
  };

  return (
    <div className="form-control">
      <label htmlFor="clientSelect" className="form-label">
        Select Client:
      </label>
      <Select
        id="clientSelect"
        options={clients}
        value={selectedClient}
        onChange={handleClientChange}
        placeholder="--Select Client--"
        isClearable
        className="form-select"
      />
    </div>
  );
}

export default ClientSelector;
