import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

function BeneficiarySelector({ clientId, onBeneficiarySelect }) {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      if (!clientId) return;
      try {
        const response = await axios.get(
          `https://api.nkolozi.com/api/Agent/get-beneficiaries/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('agent_token')}`,
            },
          }
        );
        console.log('Beneficiaries fetched for client ID:', clientId, response.data.data);
        setBeneficiaries(response.data.beneficiaries);
      } catch (error) {
        console.error('Error fetching beneficiaries:', error);
      }
    };

    fetchBeneficiaries();
  }, [clientId]);

  const handleBeneficiaryChange = (selectedOption) => {
    const beneficiaryId = selectedOption ? String(selectedOption.value) : '';
    setSelectedBeneficiaryId(beneficiaryId);
    onBeneficiarySelect(beneficiaryId);
    console.log('Selected beneficiary ID:', beneficiaryId);
  };

  const beneficiaryOptions = beneficiaries.map((beneficiary) => ({
    value: String(beneficiary.id),  // Ensure the ID is a string
    label: beneficiary.full_name,
  }));

  return (
    <div className="form-control">
      <label htmlFor="beneficiarySelect">Select Beneficiary:</label>
      <Select
        id="beneficiarySelect"
        value={beneficiaryOptions.find((option) => option.value === selectedBeneficiaryId) || null}
        onChange={handleBeneficiaryChange}
        options={beneficiaryOptions}
        isDisabled={!clientId}
        className="form-control"
        placeholder="--Select Beneficiary--"
        noOptionsMessage={() => 'No beneficiaries available'}
      />
    </div>
  );
}

export default BeneficiarySelector;
