import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Form, Button, Card, Spinner } from 'react-bootstrap';
import Nav from './Nav';
import SideNav from './SideNav';
import NewSide from './NewSide';

// Predefined list of countries and their corresponding codes
const countryList = [
  { name: 'South Africa', code: '+27', IC: 'ZA' },
  { name: 'Zimbabwe', code: '+263', IC: 'ZW' },
  { name: 'Botswana', code: '+267', IC: 'BW' },
  // Add more countries here if needed
];

const CreateBeneficiary = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    national_id: '',
    relationship: '',
    country: '',
    address: '',
    city: '',
    gender: '',
    street: '',
    suburb: '',
    district: '',
    phone: '',
    client_id: '', // Client ID will be set based on the selected client
  });

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fetchingClients, setFetchingClients] = useState(true);

  // Fetch clients from the API
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('agent_token');
        const response = await axios.get('https://api.nkolozi.com/api/Agent/clients', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setClients(
            response.data.data.map((client) => ({
              value: client.id,
              label: `${client.name} ${client.surname}`,
            }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch clients:', error);
        setError('Failed to fetch clients. Please try again.');
      } finally {
        setFetchingClients(false);
      }
    };

    fetchClients();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClientChange = (selectedOption) => {
    setFormData({
      ...formData,
      client_id: selectedOption ? selectedOption.value : '',
    });
  };

  const handleCountryChange = (e) => {
    const selectedIC = e.target.value;
    const countryInfo = countryList.find((country) => country.IC === selectedIC);

    if (countryInfo) {
      setFormData((prevState) => ({
        ...prevState,
        country: countryInfo.IC,
        phone: countryInfo.code,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        country: '',
        phone: '',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/add-beneficiary',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess('Beneficiary added successfully!');
        setFormData({
          full_name: '',
          national_id: '',
          relationship: '',
          country: '',
          address: '',
          city: '',
          gender: '',
          street: '',
          suburb: '',
          district: '',
          phone: '',
          client_id: '',
        });
        console.log("form data", formData);
      } else {
        setError('Failed to add beneficiary. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred. Please try again.');
        console.log(error.response);
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NewSide />
      <main id="main" className="main">
        <div className="container mt-5">
          <div>
            <Card style={{ width: '100%', maxWidth: '500px' }}>
              <Card.Header className="text-center">
                <h4>Add New Beneficiary</h4>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Client</Form.Label>
                    {fetchingClients ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <Select
                        options={clients}
                        onChange={handleClientChange}
                        value={clients.find((client) => client.value === formData.client_id) || null}
                        isClearable
                        placeholder="Select Client"
                      />
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="full_name"
                      placeholder="Enter Full Name"
                      value={formData.full_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>National ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="national_id"
                      placeholder="Enter National ID"
                      value={formData.national_id}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Relationship</Form.Label>
                    <Form.Select
                      name="relationship"
                      value={formData.relationship}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Relationship
                      </option>
                      <option value="1">Friend</option>
                      <option value="2">Relative</option>
                      <option value="3">Parent</option>
                      <option value="4">Sibling</option>
                      <option value="5">Other</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      name="country"
                      value={formData.country}
                      onChange={handleCountryChange}
                      required
                    >
                      <option value="">Select Country</option>
                      {countryList.map((country, index) => (
                        <option key={index} value={country.IC}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Enter Address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Street</Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter Street"
                    value={formData.street}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Surbub</Form.Label>
                  <Form.Control
                    type="text"
                    name="suburb"
                    placeholder="Enter Suburb"
                    value={formData.suburb}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="Enter City"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    name="district"
                    placeholder="Enter district"
                    value={formData.district}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  {error && <div className="alert alert-danger">{error}</div>}
                  {success && <div className="alert alert-success">{success}</div>}

                  <Button variant="primary" type="submit" disabled={loading} className="w-100">
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Submitting...
                      </>
                    ) : (
                      'Add Beneficiary'
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateBeneficiary;
