import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const NewTop = () => {
    const [user, setUser] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleUserActivity = () => {
        const tokenExpiration = localStorage.getItem('token_expiration');
        if (tokenExpiration && new Date().getTime() > tokenExpiration) {
          localStorage.removeItem('agent_token');
          localStorage.removeItem('token_expiration');
          navigate('/');
        }
      };
      
      useEffect(() => {
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
      
        return () => {
          window.removeEventListener('click', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
        };
      }, [navigate]);
  
    useEffect(() => {
      const storedToken = localStorage.getItem('agent_token');
      if (!storedToken) {
        console.log("token not set");
        navigate('/');
      } else {
        console.log(storedToken);
      }
    }, []);
    
  
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        setError(null);
  
        const token = localStorage.getItem('agent_token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Application-Type': 'application/json',
        };
  
        try {
          const response = await axios.get('https://api.nkolozi.com/api/Agent/agent', { headers });
          setUser(response.data.data.name);
  
          console.log(response.data.data.name);
          if (response.data.data.status !== 'active' || response.data.data.user_type !== 'agent') {
            await handleLogout();
          }
        } catch (error) {
          setError(error.message || 'An error occurred while fetching Agent details.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
  
  
    const handleLogout = async () => {
      const token = localStorage.getItem('agent_token');
      console.log(token)
      try {
        await fetch('https://api.nkolozi.com/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        localStorage.removeItem('agent_token');
       // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/');
      } catch (error) {
        console.error('An error occurred during logout', error);
      }
    };

  return (
    <>
  <header id="header" className="header fixed-top d-flex align-items-center" style={{ backgroundColor: '#b03a30' }}>
  <div className="d-flex align-items-center justify-content-between">
    <a href="/dashboard" className="logo d-flex align-items-center">
      <img src="Logoo.png" alt />
      <span className="d-none d-lg-block">Nkolozi</span>
    </a>
    <i className="bi bi-list toggle-sidebar-btn" />
  </div>{/* End Logo */}
  
  <nav className="header-nav ms-auto">
    <ul className="d-flex align-items-center">
      <li className="nav-item d-block d-lg-none">
        <a className="nav-link nav-icon search-bar-toggle " href="#">
          <i className="bi bi-search" />
        </a>
      </li>{/* End Search Icon*/}
     
   
      <li className="nav-item dropdown pe-3">
        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
          <img src="def.jpeg" alt="Profile" className="rounded-circle" />
          <span className="d-none d-md-block dropdown-toggle ps-2">{user}</span>
        </a>{/* End Profile Iamge Icon */}
        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
          <li className="dropdown-header">
            <h6>{user}</h6>
            <span>Nkolozi</span>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item d-flex align-items-center" href="/settings">
              <i className="bi bi-person" />
              <span>My Profile</span>
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item d-flex align-items-center" href="/settings">
              <i className="bi bi-gear" />
              <span>Account Settings</span>
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item d-flex align-items-center" href="/logout">
              <i className="bi bi-box-arrow-right" />
              <span>Sign Out</span>
            </a>
          </li>
        </ul>{/* End Profile Dropdown Items */}
      </li>{/* End Profile Nav */}
    </ul>
  </nav>{/* End Icons Navigation */}
</header>
<Outlet/>
</>
  )
}

export default NewTop