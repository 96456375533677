import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import loadingGif from './Spinner@1x-1.0s-200px-200px.gif';
import axios from 'axios';

function Login() {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reg_id, setReg_id] = useState('');
  const [password, setPassword] = useState('');
  const backgrounds = ['url(/bg4.jpg)', 'url(/bg5.jpg)', 'url(/bg6.jpg)'];
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 5000); // Change background every 5 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('agent_token');
    const expiration = localStorage.getItem('token_expiration');

    if (token && expiration) {
      const now = new Date().getTime();
      if (now > parseInt(expiration)) {
        localStorage.removeItem('agent_token');
        localStorage.removeItem('token_expiration');
        localStorage.removeItem('user_id');
        navigate('/login');
      } else {
        navigate('/dashboard');
        window.location.reload();
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      console.log('Attempting to log in with:', { reg_id, password });

      const response = await axios.post('https://api.nkolozi.com/api/employee_login', { reg_id, password });

      if (response.data && response.data.data) {
        const token = response.data.data;
        localStorage.setItem('agent_token', token);

        const userId = response.data.user ? response.data.user.id : null;
        if (userId) {
          localStorage.setItem('user_id', userId);
        }

        // Set token expiration to 5 minutes from now
        const expirationTime = new Date().getTime() + 15 * 60 * 60 * 1000; 
        localStorage.setItem('token_expiration', expirationTime.toString());

        navigate('/dashboard');
        window.location.reload();
      } else {
        console.error('Unexpected response structure:', response.data);
        setError('Unexpected response structure from the server. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setError(error.response.data.message || 'An error occurred. Please try again.');
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('No response received from server. Please check your network connection.');
      } else {
        console.error('Error message:', error.message);
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container" style={{ backgroundImage: backgrounds[backgroundIndex] }}>
      <div className="login-form">
        {loading ? (
          <>
            <div className="signing-in-text">Signing you in...</div>
            <div className="loading-container">
              <img src={loadingGif} alt="Loading..." className="loading-gif" />
            </div>
          </>
        ) : (
          <>
            <div className="logo">Nkolozi</div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="agent-id"
                  name="agent-id"
                  placeholder="Agent ID"
                  value={reg_id}
                  onChange={(e) => setReg_id(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit">Login</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
