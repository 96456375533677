import React from 'react'
import SideNav from '../Components/SideNav'
import Main from '../Components/Main'
import NewSide from '../Components/NewSide'

function Dashboard() {
  return (
    <div >
  
  
    <Main/>
    </div>
  )
}

export default Dashboard
