import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';    

const ChangeCurrComp = () => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchCurrencies = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('agent_token');
        const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrencies(response.data.data);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrencies();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const token = localStorage.getItem('agent_token');
      await axios.post('https://api.nkolozi.com/api/Agent/change-transaction-currency', 
        {
          to: selectedCurrency,
          transaction_id: transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResponseMessage('Currency changed successfully');
    } catch (error) {
      console.error('Error changing currency:', error);
      setResponseMessage('Failed to change currency',error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <main id="main" className="main">
    <Container >
        <div >
      <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
     
          Convert Transaction Currency
         
        </Typography>
      </Paper>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3 ">
          <Col xs={6} md={4} lg={3}>
            <Form.Group controlId="transactionId">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control 
                size="sm"
                type="text" 
                placeholder="Enter ID" 
                value={transactionId} 
                onChange={(e) => setTransactionId(e.target.value)} 
                required 
                style={{ width: '100%', fontSize: '0.85rem' }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 ">
          <Col xs={6} md={4} lg={3}>
            <Form.Group controlId="currencySelect">
              <Form.Label>Currency</Form.Label>
              <Form.Control 
                size="sm"
                as="select" 
                value={selectedCurrency} 
                onChange={(e) => setSelectedCurrency(e.target.value)} 
                required
                style={{ width: '100%', fontSize: '0.85rem' }}
              >
                <option value="">Select</option>
                {loading ? (
                  <option>Loading...</option>
                ) : (
                  currencies.map(currency => (
                    <option key={currency.id} value={currency.id}>
                      {currency.name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 ">
          <Col xs="auto">
            <Button variant="primary" size="sm" type="submit" disabled={submitting}>
              {submitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Change'}
            </Button>
          </Col>
        </Row>
      </Form>
      {responseMessage && (
        <Row >
          <Col xs="auto">
            <Alert variant="info" className="mt-3" style={{ fontSize: '0.85rem' }}>
              {responseMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
    </main>
  );
};

export default ChangeCurrComp;
