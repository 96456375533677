import React from 'react'
import SideNav from '../Components/SideNav'
import CashoutComp from '../Components/CashoutComp'
import NewSide from '../Components/NewSide'

function Cashout() {
  return (
    <div>
     <NewSide/>
     <CashoutComp/> 
    </div>
  )
}

export default Cashout
